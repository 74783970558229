import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssetGroupingItem, AssetGroupingService } from 'app/services/assets/asset-grouping.service';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { AppService } from 'app/app.service';
import { isFunction } from 'lodash';
import { EventActionFilter, EventFilter } from '@key-telematics/fleet-api-client';


@Injectable()
export class EventFilterService {

    private ownerId = this.app.client && this.app.client.id; // can be overridden by consumers

    constructor(
        protected app: AppService,
        protected i18n: TranslateService,
        protected grouping: AssetGroupingService
    ) {
    }

    EVENT_CONDITIONS: any = {
        time: {
            mode: { type: 'dropdown', values: ['inside', 'outside'] },
            time1: { type: 'time' },
            time2: { type: 'time' },
            dow: { type: 'checklist', values: ['0', '1', '2', '3', '4', '5', '6'] },
        },
        zone: {
            mode: { type: 'dropdown', values: ['inside', 'outside'] },
            zone: { type: 'dropdown', values: this.getZoneSelectionTree },
        },
        linked: {
            mode: { type: 'dropdown', values: ['in', 'out'] },
            asset: { type: 'dropdown', values: () => this.getAssetSelectionTree(['categories', 'costcentres', 'groups', 'types']) },
        },
        active: {
            state: { type: 'dropdown', values: ['0', '1'] },
        },
        speed: {
            mode: { type: 'dropdown', values: ['greater', 'less'] },
            speed: { type: 'speed' },
        },
        distance: {
            mode: { type: 'dropdown', values: ['greater', 'less'] },
            distance: { type: 'distance' },
        },
        duration: {
            mode: { type: 'dropdown', values: ['greater', 'less'] },
            duration: { type: 'duration' },
        },
        digital: {
            input: { type: 'dropdown', values: () => this.getIoTargetList(['digital_input']), linkedTo: 'state' },
            state: { type: 'dropdown', values: this.getIoValues },
        },
        state: {
            profile: { type: 'dropdown', values: this.getStateTargetList, linkedTo: 'state' },
            state: { type: 'dropdown', values: this.getStateValues },
        },
    };

    // remove flag check once happy with trip limits feature (tripviolationevent)
    ACTION_EVENTS: any = this.app.flags?.tripLimitsEnabled() ? {
        zoneevent: ['enter', 'exit', 'range_enter', 'range_exit'],
        assetactivationevent: ['activate', 'deactivate'],
        triggeredevent: ['alarm_active', 'alarm_armed', 'alarm_disarmed', 'analog_jump', 'analog_range', 'battery_active', 'battery_disconnect', 'battery_low', 'battery_reconnect', 'curfew_violation', 'excessive_stop_end', 'excessive_stop_start', 'gps_antenna_fault', 'gps_failure', 'gps_jammed', 'gps_signal_low', 'gsm_antenna_fault', 'gsm_jammed', 'gsm_signal_low', 'harsh_accel', 'harsh_brake', 'harsh_corner', 'impact', 'motion_end', 'motion_start', 'over_rpm', 'panic', 'power_disconnect', 'power_high', 'power_low', 'power_off', 'power_on', 'power_reconnect', 'tilt', 'towing', 'unauthorized_movement', 'vin_changed', 'voice_call_ended', 'voice_call_started', 'voice_callback'],
        assetstatechangeevent: ['change'],
        digitalinputevent: ['activate', 'deactivate'],
        valueinputevent: ['activate'],
        analoginputevent: ['low', 'high', 'drop', 'jump'],
        temperatureinputevent: ['out_range', 'in_range', 'low', 'high'],
        overspeedevent: ['start', 'band_end'],
        excessiveidleevent: ['start'],
        fuelingevent: ['transaction'],
        reminderevent: ['odo', 'hours', 'time'],
        tagevent: ['gained', 'lost', 'alarm'],
        textmessageevent: ['incoming'],
        geolockevent: ['violation'],
        geoborderevent: ['country', 'state'],
        camerainputevent: ['video'],
        tripviolationevent: ['distance', 'active', 'inactive']
    } : {
        zoneevent: ['enter', 'exit', 'range_enter', 'range_exit'],
        assetactivationevent: ['activate', 'deactivate'],
        triggeredevent: ['alarm_active', 'alarm_armed', 'alarm_disarmed', 'analog_jump', 'analog_range', 'battery_active', 'battery_disconnect', 'battery_low', 'battery_reconnect', 'curfew_violation', 'excessive_stop_end', 'excessive_stop_start', 'gps_antenna_fault', 'gps_failure', 'gps_jammed', 'gps_signal_low', 'gsm_antenna_fault', 'gsm_jammed', 'gsm_signal_low', 'harsh_accel', 'harsh_brake', 'harsh_corner', 'impact', 'motion_end', 'motion_start', 'over_rpm', 'panic', 'power_disconnect', 'power_high', 'power_low', 'power_off', 'power_on', 'power_reconnect', 'tilt', 'towing', 'unauthorized_movement', 'vin_changed', 'voice_call_ended', 'voice_call_started', 'voice_callback'],
        assetstatechangeevent: ['change'],
        digitalinputevent: ['activate', 'deactivate'],
        valueinputevent: ['activate'],
        analoginputevent: ['low', 'high', 'drop', 'jump'],
        temperatureinputevent: ['out_range', 'in_range', 'low', 'high'],
        overspeedevent: ['start', 'band_end'],
        excessiveidleevent: ['start'],
        fuelingevent: ['transaction'],
        reminderevent: ['odo', 'hours', 'time'],
        tagevent: ['gained', 'lost', 'alarm'],
        textmessageevent: ['incoming'],
        geolockevent: ['violation'],
        geoborderevent: ['country', 'state'],
        camerainputevent: ['video'],
    }

    ACTION_TARGETS: any = {
        zoneevent: 'zones',
        assetstatechangeevent: 'state',
        overspeedevent: {
            band_end: 'overspeedband',
        },
        digitalinputevent: 'digitals',
        valueinputevent: 'values',
        analoginputevent: 'analogs',
        temperatureinputevent: 'temperatures',
    };


    setOwnerId(ownerId: string) {
        this.ownerId = ownerId;
    }

    buildAssetSelectionTree(costCentres: AssetGroupingItem[], assetTypes: AssetGroupingItem[], assetGroups: AssetGroupingItem[], assetCategories: AssetGroupingItem[], allowedAssetTypes?: string[]): KuiTreeSelectNode[] {

        const nodes = [];

        const addAssetTypeGroups = (assetType: { id: string, name: string }) => {
            const assetTypeName = this.i18n.instant(`SHARED.ASSET_TYPES.${assetType.name.toUpperCase().replace(/ /g, '_')}`);
            const anyDesc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.ANY', { assetType: assetTypeName });

            const node: KuiTreeSelectNode = {
                id: assetType.id,
                name: assetTypeName,
                children: [],
                data: {
                    actorId: '00000000-0000-0000-0000-000000000000',
                    actorSelectionType: 'any',
                    actorType: 'asset',
                    actorTypeId: assetType.id,
                    actorTypeName: assetTypeName,
                    text: anyDesc,
                },
            };

            nodes.push(node);

            node.children.push({
                id: 'any.' + assetType.id,
                name: anyDesc,
                children: null,
                data: {
                    actorId: '00000000-0000-0000-0000-000000000000',
                    actorSelectionType: 'any',
                    actorType: 'asset',
                    actorTypeId: assetType.id,
                    actorTypeName: assetTypeName,
                    text: anyDesc,
                },
            });

            if (costCentres.length > 0) {
                node.children.push({
                    id: 'costcentre.' + assetType.id,
                    name: this.i18n.instant('FORMS.ASSETFILTER.GROUP_TREE.COST_CENTRE'),
                    children: AssetGroupingService.toTree(costCentres.map((item: any) => {
                        const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.COST_CENTRE', { assetType: assetTypeName, name: item.name });
                        return {
                            ...item,
                            id: `costcentre.${assetType.id}.${item.id}`,
                            data: {
                                actorId: item.id,
                                actorName: item.name,
                                actorSelectionType: 'accessGroup',
                                actorType: 'asset',
                                actorTypeId: assetType.id,
                                actorTypeName: assetTypeName,
                                text: desc,
                            },
                        };
                    })),
                });
            }

            if (assetGroups.length > 0) {
                node.children.push({
                    id: 'group.' + assetType.id,
                    name: this.i18n.instant('FORMS.ASSETFILTER.GROUP_TREE.GROUP'),
                    children: AssetGroupingService.toTree(assetGroups.map((item: any) => {
                        const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.GROUP', { assetType: assetTypeName, name: item.name });
                        return {
                            ...item,
                            id: `group.${assetType.id}.${item.id}`,
                            data: {
                                actorId: item.id,
                                actorName: item.name,
                                actorSelectionType: 'group',
                                actorType: 'asset',
                                actorTypeId: assetType.id,
                                actorTypeName: assetTypeName,
                                text: desc,
                            },
                        };
                    })),
                });
            }

            if (assetCategories.length > 0) {
                node.children.push({
                    id: 'category.' + assetType.id,
                    name: this.i18n.instant('FORMS.ASSETFILTER.GROUP_TREE.CATEGORY'),
                    children: AssetGroupingService.toTree(assetCategories.map((item: any) => {
                        const desc = this.i18n.instant('FORMS.ASSETFILTER.GROUP_DESC.CATEGORY', { assetType: assetTypeName, name: item.name });
                        return {
                            ...item,
                            id: `category.${assetType.id}.${item.id}`,
                            data: {
                                actorId: item.id,
                                actorName: item.name,
                                actorSelectionType: 'category',
                                actorType: 'asset',
                                actorTypeId: assetType.id,
                                actorTypeName: assetTypeName,
                                text: desc,
                            },
                        };
                    })),
                });
            }
        };

        if (allowedAssetTypes && allowedAssetTypes.includes('asset')) {
            addAssetTypeGroups({ id: '00000000-0000-0000-0000-000000000000', name: 'Asset' });
        }

        assetTypes.sort((a, b) => a.name.localeCompare(b.name));
        assetTypes.forEach(assetType => {
            if (!allowedAssetTypes || allowedAssetTypes.length === 0 || allowedAssetTypes.includes(assetType.name.toLowerCase())) {
                addAssetTypeGroups(assetType);
            }
        });

        return nodes;
    }

    getAssetSelectionTree(groupTypes?: ('costcentres' | 'groups' | 'types' | 'categories')[]): Promise<KuiTreeSelectNode[]> {
        groupTypes = groupTypes || ['costcentres', 'groups', 'types', 'categories'];
        return Promise.all([
            groupTypes.includes('costcentres') ? this.grouping.getCostCentres(this.ownerId) : [],
            groupTypes.includes('types') ? this.grouping.getAssetTypes(this.ownerId) : [],
            groupTypes.includes('groups') ? this.grouping.getAssetGroups(this.ownerId) : [],
            groupTypes.includes('categories') ? this.grouping.getAssetCategories(this.ownerId) : [],
        ]).then(([costCentres, assetTypes, assetGroups, assetCategories]) => {
            return this.buildAssetSelectionTree(costCentres, assetTypes, assetGroups, assetCategories);
        });
    }

    buildZoneSelectionTree(zoneGroups: AssetGroupingItem[]): KuiTreeSelectNode[] {
        const nodes = [];

        ['location', 'nogo', 'keepin', 'route'].forEach(zoneType => {

            const zoneTypeName = this.i18n.instant(`SHARED.ZONE_TYPES.${zoneType.toUpperCase()}`);


            const anyDesc = this.i18n.instant('FORMS.ZONEFILTER.GROUP_DESC.ANY', { zoneType: zoneTypeName });

            const node: KuiTreeSelectNode = {
                id: zoneType,
                name: zoneTypeName,
                children: [],
                data: {
                    targetId: '00000000-0000-0000-0000-000000000000',
                    targetSelectionType: 'any',
                    targetType: 'zone',
                    targetTypeId: zoneType,
                    targetTypeName: zoneTypeName,
                    text: anyDesc,
                },
            };

            nodes.push(node);

            node.children.push({
                id: 'any.' + zoneType,
                name: anyDesc,
                children: null,
                data: {
                    targetId: '00000000-0000-0000-0000-000000000000',
                    targetSelectionType: 'any',
                    targetType: 'zone',
                    targetTypeId: zoneType,
                    targetTypeName: zoneTypeName,
                    text: anyDesc,
                },
            });

            if (zoneGroups.length > 0) {
                node.children.push({
                    id: 'group.' + zoneType,
                    name: this.i18n.instant('FORMS.ZONEFILTER.GROUP_TREE.GROUP'),
                    children: AssetGroupingService.toTree(zoneGroups.map((item: any) => {
                        const desc = this.i18n.instant('FORMS.ZONEFILTER.GROUP_DESC.GROUP', { zoneType: zoneTypeName, name: item.name });
                        return {
                            ...item,
                            id: `${zoneType}.${item.id}`,
                            data: {
                                targetId: item.id,
                                targetName: item.name,
                                targetSelectionType: 'group',
                                targetType: 'zone',
                                targetTypeId: zoneType,
                                targetTypeName: zoneTypeName,
                                text: desc,
                            },
                        };
                    })),
                });
            }
        });

        return nodes;
    }

    getZoneSelectionTree(): Promise<KuiTreeSelectNode[]> {
        return Promise.all([
            this.grouping.getZoneGroups(this.ownerId),
        ]).then(([zoneGroups]) => {
            return this.buildZoneSelectionTree(zoneGroups);
        });
    }

    async getValueInputSelectionTree(): Promise<KuiTreeSelectNode[]> {
        const result = await this.app.api.entities.listIoTypes(this.ownerId, true, 0, 300, 'name', 'state=active,type=value_input');
        return result.items.map(item => ({
            id: item.id,
            name: item.name,
            hasChildren: true,
            getChildren: async () => {
                const ioType = await this.app.api.entities.getIoType(item.id);
                return [
                    {
                        id: 'any.' + item.id,
                        name: this.i18n.instant('SHARED.ANY'),
                        data: {
                            targetType: 'value_input',
                            targetTypeId: ioType.id,
                            targetTypeName: ioType.name,
                            targetSelectionType: 'any',
                            targetId: '00000000-0000-0000-0000-000000000000',
                            targetName: this.i18n.instant('SHARED.ANY'),
                            text: this.i18n.instant('SHARED.ALERTS.TARGETS.VALUE_INPUT.ANY', { targetTypeName: ioType.name }),
                        },
                    },
                    ...(ioType.lookups || []).map(lookup => ({
                        id: lookup.id,
                        name: lookup.id + ': ' + lookup.name,
                        data: {
                            targetType: 'value_input',
                            targetTypeId: ioType.id,
                            targetTypeName: ioType.name,
                            targetSelectionType: 'specific',
                            targetId: lookup.id,
                            targetName: lookup.name,
                            text: this.i18n.instant('SHARED.ALERTS.TARGETS.VALUE_INPUT.SPECIFIC', { targetTypeName: ioType.name, targetId: lookup.id, targetName: lookup.name }),
                        },
                    })),
                ];
            },
        }));
    }


    getActionEventsTree(): KuiTreeSelectNode[] {
        const result = Object.keys(this.ACTION_EVENTS).map(eventClass => {
            return {
                id: `event-${eventClass}`,
                name: this.i18n.instant(`SHARED.EVENTS.${eventClass.toUpperCase()}.NAME`),
                children: this.ACTION_EVENTS[eventClass].map(eventType => {
                    let targetType: any = this.ACTION_TARGETS[eventClass];
                    if (typeof targetType === 'object') {
                        targetType = targetType[eventType];
                    }

                    return {
                        id: `event-${eventClass}-${eventType}`,
                        name: this.i18n.instant(`SHARED.EVENTS.${eventClass.toUpperCase()}.TYPES.${eventType.toUpperCase()}.NAME`),
                        data: {
                            eventClass: eventClass,
                            eventType: eventType,
                            targetType: targetType,
                            text: this.i18n.instant(`SHARED.EVENTS.${eventClass.toUpperCase()}.TYPES.${eventType.toUpperCase()}.ACTION`),
                        },
                    };
                }),
            };
        });
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
    }

    getTargetNodes(targetType: string): Promise<KuiTreeSelectNode[]> {
        switch (targetType) {
            case 'zones': return this.getZoneSelectionTree();
            case 'digitals': return this.getIoTargetTree(['digital_input']);
            case 'analogs': return this.getIoTargetTree(['analog_input', 'can_input']);
            case 'temperatures': return this.getIoTargetTree(['temperature_input']);
            case 'values': return this.getValueInputSelectionTree();
            case 'state': return this.getStateTargetTree();
            case 'overspeedband': return this.getOverspeedBandTargetTree();
        }

        return null;
    }

    async getIoTargetTree(types: string[]): Promise<KuiTreeSelectNode[]> {
        const typeFilters = types.map(t => `type=${t}`).join('|');
        const type = types.length > 1 ? `(${typeFilters})` : typeFilters;
        const filter = type ? `state=active,${type}` : `state=active`;
        const result = await this.app.api.entities.listIoTypes(this.ownerId, true, 0, 300, 'name', filter);
        return result.items.map(item => ({
            id: item.id,
            name: item.name,
            data: {
                targetType: item.type,
                targetTypeId: item.type,
                targetTypeName: item.type,
                targetSelectionType: 'specific',
                targetId: item.id,
                targetName: item.name,
                text: item.name,
            },
        }));
    }

    async getIoTargetList(types: string[]): Promise<{ id: string, name: string }[]> {
        const typeFilters = types.map(t => `type=${t}`).join('|');
        const type = types.length > 1 ? `(${typeFilters})` : typeFilters;
        const filter = type ? `state=active,${type}` : `state=active`;
        const result = await this.app.api.entities.listIoTypes(this.ownerId, true, 0, 300, 'name', filter);
        return result.items.map(item => ({
            id: item.id,
            name: item.name,
        }));
    }

    getIoValues(data: { input: string }): Promise<KuiTreeSelectNode[]> {
        if (data && data.input) {
            return this.app.api.entities.getIoType(data.input).then(result => {
                return [
                    { id: '0', name: (result.text && result.text.inactive) || this.i18n.instant('SHARED.ALERTS.CONDITIONS.DIGITAL.STATE_0') },
                    { id: '1', name: (result.text && result.text.active) || this.i18n.instant('SHARED.ALERTS.CONDITIONS.DIGITAL.STATE_1') },
                ];
            }).catch(err => {
                console.error(err);
                // the asset state profile was deleted, we don't want to crash the app
                return Promise.resolve([]);
            });
        } else {
            return Promise.resolve([]);
        }
    }

    async getOverspeedBandTargetTree(): Promise<KuiTreeSelectNode[]> {
        const client = await this.app.api.accounts.getClient(this.ownerId);
        const [vendorProfiles, clientProfiles] = await Promise.all([
            this.app.api.entities.listOverspeedProfiles(client.owner.id, undefined, undefined, undefined, undefined, 'state=active'),
            this.app.api.entities.listOverspeedProfiles(client.id, undefined, undefined, undefined, undefined, 'state=active'),
        ]);
        const items = [...vendorProfiles.items, ...clientProfiles.items].sort((a, b) => a.name.localeCompare(b.name));
        return items.map(item => ({
            id: item.id,
            name: item.name,
            children: item.items.map(band => ({
                id: band.id,
                name: band.name,
                data: {
                    targetType: 'overspeedband',
                    targetTypeId: item.id,
                    targetTypeName: item.name,
                    targetSelectionType: 'specific',
                    targetId: band.id,
                    targetName: band.name,
                    text: this.i18n.instant('SHARED.ALERTS.TARGETS.OVERSPEEDBAND.SPECIFIC', { targetTypeName: item.name, targetName: band.name }),
                },
            })),
        }));
    }

    async getStateTargetTree(): Promise<KuiTreeSelectNode[]> {
        const result = await this.app.api.entities.listAssetStateProfiles(this.ownerId, undefined, undefined, 'name', 'state=active');
        return result.items.map(item => ({
            id: item.id,
            name: item.name,
            children: item.items.map(state => ({
                id: state.id,
                name: state.state,
                data: {
                    targetType: 'state',
                    targetTypeId: item.id,
                    targetTypeName: item.name,
                    targetSelectionType: 'specific',
                    targetId: state.id,
                    targetName: state.state,
                    text: this.i18n.instant('SHARED.ALERTS.TARGETS.STATE.SPECIFIC', { targetTypeName: item.name, targetName: state.state }),
                },
            })),
        }));
    }

    async getStateTargetList(): Promise<{ id: string, name: string }[]> {
        const result = await this.app.api.entities.listAssetStateProfiles(this.ownerId, undefined, undefined, 'name', 'state=active');
        return result.items.map(item => ({
            id: item.id,
            name: item.name,
        }));
    }


    getStateValues(data: { profile: string }): Promise<KuiTreeSelectNode[]> {
        if (data && data.profile) {
            return this.app.api.entities.getAssetStateProfile(data.profile).then(result => {
                return result.items.map(item => ({
                    id: item.state,
                    name: item.state,
                }));
            }).catch(_err => {
                // the asset state profile was deleted, we don't want to crash the app
                return Promise.resolve([]);
            });
        } else {
            return Promise.resolve([]);
        }
    }


    getConditionsTree(action: Pick<EventActionFilter, 'eventClass' | 'eventType'>): KuiTreeSelectNode[] {
        return Object.keys(this.EVENT_CONDITIONS)
            .filter(key => {
                switch (key) {
                    case 'distance': return action.eventClass === 'fuelingevent' || (action.eventClass === 'overspeedevent' && action.eventType === 'band_end');
                    case 'duration': return (action.eventClass === 'overspeedevent' && action.eventType === 'band_end');
                    default: return true;
                }
            })
            .map(key => ({
                id: key,
                name: this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${key.toUpperCase()}.NAME`),
                data: {
                    type: key,
                    values: {},
                    text: {},
                },
            }));
    }

    getConditionText(type: string, values: any): string {
        return this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${type.toUpperCase()}.TEXT`, values);
    }

    getConditionOptions(type: string, option: string, data?: any): Promise<{ type: string, linkedTo?: string, values: KuiTreeSelectNode[] }> {

        data = data || {};

        if (!this.EVENT_CONDITIONS[type] || !this.EVENT_CONDITIONS[type][option]) {
            return Promise.resolve(null);
        }

        return Promise.resolve().then(() => {
            const values = this.EVENT_CONDITIONS[type][option].values;
            if (isFunction(values)) {
                return values.bind(this)(data);
            } else {
                return (values || []).map(x => ({
                    id: x,
                    name: this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${type.toUpperCase()}.${option.toUpperCase()}_${x.toUpperCase()}`),
                }));
            }
        }).then(values => {
            const result: any = {
                type: this.EVENT_CONDITIONS[type][option].type,
                values: values,
            };
            const linked = this.EVENT_CONDITIONS[type][option].linkedTo;
            if (linked) {
                result.linkedTo = linked;
            }
            return result;
        });
    }


    /** turns a filter object into readable text. adapted from the legacy web app. */
    getEventFilterText(filter: EventFilter): string {

        if (!filter) {
            return null;
        }

        const getText = (lookupType) => {
            let text = '[' + lookupType + ']';
            const val = filter[lookupType];
            if (val) {
                try {
                    if (lookupType === 'actor') {
                        text = this.i18n.instant(`SHARED.ALERTS.ACTORS.${val.actorType.toUpperCase()}.${val.actorSelectionType.toUpperCase()}`);
                    }
                    if (lookupType === 'target') {
                        text = this.i18n.instant(`SHARED.ALERTS.TARGETS.${val.targetType.toUpperCase()}.${val.targetSelectionType.toUpperCase()}`);
                    }
                    if (lookupType === 'action') {
                        text = this.i18n.instant(`SHARED.EVENTS.${val.eventClass.toUpperCase()}.TYPES.${val.eventType.toUpperCase()}.ACTION`);
                    }
                    Object.keys(val).forEach(key => {
                        const value = val[key];
                        let t = value;
                        if (key === 'actorTypeName') {
                            const tkey = `SHARED.ASSET_TYPES.${value.toUpperCase().replace(' ', '_')}`;
                            t = this.i18n.instant(tkey);
                            if (t === tkey) { // translation failed, use the actual value instead (it's probably already translated)
                                t = value;
                            }
                        }
                        if (key === 'targetTypeName') {
                            t = `${value}`;
                        }
                        text = text.replace('{{' + key + '}}', t);
                    });
                } catch (msg) {
                    console.log(msg);
                }
            }
            return text;
        };
        let str = this.i18n.instant('SHARED.ALERTS.FILTER_SENTENCE');
        if (!filter.actor) {
            str = '{action} {target}';
        }
        str = str.replace('{actor}', getText('actor')).replace('{action}', getText('action'));
        if (filter.action && filter.action.targetType) {
            str = str.replace('{target}', getText('target'));
        } else {
            str = str.replace(' {target}', '');
        }
        if (filter.conditions) {
            for (let x = 0; x < filter.conditions.length; x++) {
                const condition = filter.conditions[x];
                if (condition.type && condition.type !== 'null') { // FLT-86 due to a bug the condition.type may be a null string instead of null
                    let t = this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${condition.type.toUpperCase()}.TEXT`);
                    Object.keys(condition.text).forEach(key => {
                        const val = condition.text[key];
                        let text = '';
                        if (val) {
                            text = val;
                            if (val instanceof Array) {
                                text = val.join(', ');
                            }
                        }
                        t = t.replace('{' + key + '}', text);
                    });
                    str += ' ' + this.getConditionPrefix(x) + ' (' + t + ')';
                }
            }
        }
        str += '.';
        return str;
    }

    getConditionPrefix(_index: number): string {
        return this.i18n.instant('SHARED.ALERTS.FILTER_AND');
    }



}
