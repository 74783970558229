@if(name()) {
    <div class="p-3 heading">
        <div> {{name()}} </div>
    </div>
}

<div class="content">
    @if(loading()) {
        <kui-alert 
            type="none"
            class="inner key-alert-wrapper">
            <kui-loader
                class="mr-4"
                size="medium"></kui-loader>
            <span>{{ 'MEDIA.LIVESTREAM.REQUESTING' | translate }}</span>
        </kui-alert>
    } @else {
        @if(error()) {
            @if(watchLimitReached()) {
                <div class="limit-warning">
                    <div class="limit-warning--heading">
                        <kui-icon name="lock" color="danger"
                            size="lg"></kui-icon>
                        <h2>
                            {{'MEDIA.LIVESTREAM.DAILY_STREAMS_REACHED' | translate}}
                        </h2>
                    </div>
                    <p>
                        {{'MEDIA.LIVESTREAM.DAILY_STREAMS_DESC' | translate}}
                    </p>
                    <p>
                        {{'MEDIA.LIVESTREAM.TRY_LATER' | translate}}
                    </p>
                </div>
            } @else {   
                <kui-alert type="none"
                    class="key-alert-wrapper fig">
                    <kui-icon name="ban"
                        size="lg"></kui-icon>
                    <span>{{ error() }}</span>
                </kui-alert>
            }
        } @else {
            @if(videoSource()) {
                <key-videojs-player
                    class="w-100 h-100"
                    [options]="videoOptions()"
                    [source]="videoSource()"
                    (onError)="onLiveStreamError($event)">
                </key-videojs-player>

                @if(streamEnd()) {
                    <div class="player-overlay">
                        <h2 class="player-overlay--title">
                            {{'MEDIA.LIVESTREAM.STREAM_END' | translate}}
                        </h2>
                        <div class="player-overlay--actions d-flex justify-content-center align-items-center flex-column">
                            @if (saveVideo()) {
                                <p>{{'MEDIA.LIVESTREAM.VIDEO_SAVED' | translate}}</p>
                                <div class="buttons d-flex justify-content-between align-items-center">
                                    <button kui-button (click)="requestStream()">{{'MEDIA.LIVESTREAM.REQUEST_LIVE_VIDEO' | translate}}</button>
                                    <ng-content select="[savedVideoActions]"></ng-content>
                                </div>
                            } @else {
                                <p>{{'MEDIA.LIVESTREAM.REQUEST_NEW' | translate}}</p>
                                <button kui-button (click)="requestStream()">{{'MEDIA.LIVESTREAM.REQUEST_LIVE_VIDEO' | translate}}</button>
                            }
                        </div>
                    </div>
                }
            } @else {
                <div class="request">
                    <button kui-button (click)="requestStream()">{{'MEDIA.LIVESTREAM.REQUEST_LIVE_VIDEO' | translate}}</button>
                    <div class="request-action">
                        <label for="saveVideo">{{'MEDIA.LIVESTREAM.RECORD_STREAM_QUESTION' | translate}}</label>
                        <input
                            id="saveVideo"
                            type="checkbox"
                            [checked]="saveVideo()"
                            (change)="saveVideo.set(!saveVideo())"/>
                    </div>
                </div>
            }
        }
    }
</div>
