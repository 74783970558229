import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, WritableSignal, input, signal, type OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KuiModule } from 'app/key-ui';
import { MatchMediaService } from 'app/services';
import { MediaService } from 'app/services/media/media.service';
import { EMPTY, Subject, catchError, from, switchMap, takeUntil, tap } from 'rxjs';
import { VideoPlayerOptions, VjsPlayerComponent } from '../players/vjs-player/vjs-player.component';

@Component({
    selector: 'key-livestream',
    standalone: true,
    imports: [
        CommonModule,
        KuiModule,
        VjsPlayerComponent,
        TranslateModule
    ],
    templateUrl: './livestream.component.html',
    styleUrl: './livestream.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LivestreamComponent implements OnInit, OnDestroy {

    assetId = input.required<string>();
    camera = input.required<string>();
    name = input<string>();
    duration = input<number>(60);

    error: WritableSignal<string> = signal<string>(null);
    loading: WritableSignal<boolean> = signal(false);
    watchLimitReached: WritableSignal<boolean> = signal(false);
    streamEnd: WritableSignal<boolean> = signal(false);
    saveVideo: WritableSignal<boolean> = signal(false);


    videoSource: WritableSignal<any> = signal(null);
    videoOptions = signal<VideoPlayerOptions>({
        autoplay: true,
        controls: true,
        html5: {
            vhs: {
                playlistExclusionDuration: Infinity,
                maxPlaylistRetries: 0   // this is required otherwise the player will keep trying to reload the source
            }
        }
    });

    requestVideo = new Subject<{
        asset: string,
        camera: string,
        save: boolean,
        duration: number
    }>();

    private destroyed$ = new Subject<boolean>();

    constructor(
        public matchMedia: MatchMediaService,
        public media: MediaService,
        private i18n: TranslateService,
    ) { }

    ngOnInit(): void {
        this.requestVideo.pipe(
            takeUntil(this.destroyed$),
            tap(_ => {
                this.loading.set(true);
                this.streamEnd.set(false);
                this.watchLimitReached.set(false);
            }),
            switchMap(req => from(this.media.requestLiveStreamUrl(req.asset, req.camera, req.duration, req.save))),
            catchError((err) => {
                console.error(err);
                if (err.status === 400) {
                    // probably reached the asset limit
                    this.watchLimitReached.set(true);
                    this.error.set(err.message);
                } else {
                    this.error.set(this.i18n.instant('MEDIA.ERRORS.GENERIC'));
                }
                this.loading.set(false);
                return EMPTY;
            })
        ).subscribe(result => {
            if (result.status === 'success') {
                this.error.set(null);
                const hls = result.endpoints.find(x => x.startsWith('https://'));
                if (hls) {
                    this.videoSource.set({ src: hls, type: 'application/x-mpegURL' });
                } else {
                    this.error.set(this.i18n.instant('MEDIA.LIVESTREAM.NO_COMPATIBLE_STREAM'));
                }
            } else {
                this.error.set(result.status);
            }
            this.loading.set(false);
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }

    requestStream() {
        this.requestVideo.next({
            asset: this.assetId(),
            camera: this.camera(),
            save: this.saveVideo(),
            duration: this.duration()
        });
    }

    onLiveStreamError(err: any) {
        // assume any error is the stream ending...
        this.streamEnd.set(true);
        console.error(err);
    }

}
